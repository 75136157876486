@import "../../variables.modules";

.pink {
  color: $pink;
}

.green {
  color: $green;
}

.skills {
  columns: 1;
  @media only screen and (min-width: 940px) {
    columns: 2;
  }

  li {
    line-height: 1.2;
  }
}