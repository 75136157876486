.resumeContainer {
    padding: 10px;
    background-color: rgb(111, 110, 110);
    border-radius: 8px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    margin: auto;
    overflow-x: hidden; 

    .rpv-core__viewer {
        max-width: 100%;
    }

    .react-pdf__Page {
        padding: 0;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
        margin-bottom: 10px;
        transform: scale(14);
        width: 100%;
    }
}

.linkContainer {
    margin-top: 20px; 
    text-align: center; 
}

.driveLink {
    color: #007bff; 
    text-decoration: none; 
    font-weight: bold;

    &:hover {
        text-decoration: none; 
        color: gray;
    }
}

@media (min-width: 800px) {
    .resumeContainer {
        padding: 20px;
        background-color: #c5bfbf;
        border-radius: 8px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        max-width: 1200px;
        margin: auto;
        overflow-x: hidden; 
    
        .rpv-core__viewer {
            max-width: 100%;
        }
    
        .react-pdf__Page {
            padding: 0;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
            margin-bottom: 20px;
            transform: scale(14);
            width: 100%;
        }
}
}