@import "../../variables.modules";

h1 {
  font-size: 2rem;
  text-align: center;
  @media only screen and (min-width: 940px) {
    font-size: 3rem;
    text-align: left;
  }
}

h2 {
  font-size: 1.25rem;
  text-align: center;
  @media only screen and (min-width: 940px) {
    font-size: 2rem;
    text-align: left;
  }
}


.hand {
  animation-name: wave;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

a {
  color: $dark;
  transition: color 200ms ease;
}

a:hover {
  color: $lightgray;
  transition: color 200ms ease;
}

@keyframes wave {
  0% { transform: rotate( 0.0deg) }
  10% { transform: rotate(14.0deg) }
  20% { transform: rotate(-8.0deg) }
  30% { transform: rotate(14.0deg) }
  40% { transform: rotate(-4.0deg) }
  50% { transform: rotate(10.0deg) }
  60% { transform: rotate( 0.0deg) }
  100% { transform: rotate( 0.0deg) }
}

.buttonContainer {
  display: flex;
  justify-content: center; 

}

.btn {
  border: none;
  border-radius: 5px;
  padding: 12px 20px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  background-color: gray;
  color: white;
  position: relative;
  
}
@media only screen and (max-width: 600px) {
  .btn {
    font-size: 16px;    
    border-radius: 3px; 
    width: 300px;
    height: auto;
  }
}

.ribbon {
  width: 60px;
  font-size: 14px;
  padding: 4px;
  position: absolute;
  right: -25px;
  top: -12px;
  text-align: center;
  border-radius: 25px;
  font-weight: bold;
  transform: rotate(20deg);
  background-color: green;
  color: white;
}

