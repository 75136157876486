// Portfolio.module.scss

.portfolio_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    padding: 2rem;
    max-width: 1200px;
  
    @media (max-width: 768px) {
      justify-content: center;
    }
  }
  
  .portfolio_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45%;
    margin-bottom: 2rem;
    padding: 1rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #f1ecec;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    min-height: 300px;
  
    &:hover {
      transform: translateY(-10px);
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    }
  
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  
  .project_image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 1rem;
    display: block;
  }
  
  .project_title {
    margin-bottom: 0.5rem;
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    color: black;

  
  }
  
  .project_description {
    margin-bottom: 1rem;
    text-align: center;
    flex-grow: 1;
   // border: 1px;
    //border-color: #6c757d;
    //border-style: solid;
    //border-radius: 5px;
    color: black;
    width: 100%;
  }
  
  .project_links {
    display: flex;
    justify-content: center;
    gap: 1rem; 
    margin-top: 7px;
  }
  
  .project_link {
    color: #fff;
    background-color: #387ebc; // Grey color for buttons
    padding: 0.5rem 1rem;
    border-radius: 4px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.3s ease;
  
    &:hover {
      background-color: #546775;
      transform: translateY(-2px);
    }
  }
  